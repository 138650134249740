body {
  background-color: #186f8d;
  font-family: 'Poppins', sans-serif;
  font-size: 19px;
  line-height: 1.5;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main {
  margin: 0 auto;
  background: #ffffff;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
a,
a:link,
a:hover,
a:active,
a:focus,
a:visited {
  color: black;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  a,
  a:link,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #ffffff;
  }
}
h1,
h2,
h3,
h4 {
  color: #186f8d;
  line-height: 1.4;
}
p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 1.5;
}
hr {
  border: none;
  height: 1px;
  background-color: #5e5e5e;
}
.center {
  width: 100%;
  min-height: 90vh;
  text-align: center;
  padding: 24px;
}
@media (prefers-color-scheme: dark) {
  .center {
    background: #000000;
  }
  .center p {
    color: #ffffff;
  }
}
.content {
  width: 100%;
  min-height: 90vh;
  padding: 24px;
}
@media (min-width: 1000px) {
  .content {
    width: 1000px;
    padding-left: 150px;
    padding-right: 150px;
  }
}
@media (prefers-color-scheme: dark) {
  .content {
    background: #000000;
  }
  .content p {
    color: #ffffff;
  }
}
.contentBlue {
  width: 100%;
  min-height: 100vh;
  padding-left: 10%;
  padding-right: 24px;
  background-color: #186f8d;
  padding-top: env(safe-area-inset-top);
}
.contentBlue h1,
.contentBlue h2,
.contentBlue h3,
.contentBlue h4,
.contentBlue h5 {
  color: #ffffff;
}
.contentBlue p {
  color: #d4d4d4;
}
@media (prefers-color-scheme: dark) {
  .contentBlue h1,
  .contentBlue h2,
  .contentBlue h3,
  .contentBlue h4,
  .contentBlue h5,
  .contentBlue p {
    color: #000000;
  }
}
.centerDialog {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 12px;
}
.centerBlue {
  width: 100%;
  min-height: 100vh;
  text-align: center;
  padding: 24px;
  background-color: #186f8d;
  padding-top: env(safe-area-inset-top);
  color: #ffffff;
}
.centerBlue h1,
.centerBlue h2,
.centerBlue h3,
.centerBlue h4,
.centerBlue h5 {
  color: #ffffff;
}
.centerBlue p {
  color: #d4d4d4;
}
@media (max-width: 320px) {
  .centerBlue {
    padding: 12px;
  }
}
@media (prefers-color-scheme: dark) {
  .centerBlue {
    color: #323232;
  }
  .centerBlue h1,
  .centerBlue h2,
  .centerBlue h3,
  .centerBlue h4,
  .centerBlue h5 {
    color: #323232;
  }
}
.closeIcon {
  position: fixed;
  right: 15px;
  top: 20px;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
}
.tutorialDiv {
  min-height: 400px;
  padding-top: 40px;
}
.tutorialDiv h1 {
  color: #ffffff;
}
@media (max-width: 320px) {
  .tutorialDiv {
    min-height: 300px;
    padding-top: 0px;
  }
  .tutorialDiv h1 {
    font-size: 32px;
  }
  .tutorialDiv p {
    font-size: 18px;
  }
}
.tutorialCircle {
  display: inline-block;
  margin: 3px;
  width: 12px;
  height: 12px;
  border: 1px solid #ffffff;
  -moz-border-radius: 45px;
  -webkit-border-radius: 45px;
  border-radius: 45px;
}
.icons {
  font-size: 50px;
  padding-top: 4px;
  color: #ffffff;
}
.sticky {
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.headerHome {
  min-height: 90px;
  background-color: #186f8d;
  padding-left: 4%;
  padding-top: env(safe-area-inset-top);
}
.headerNav {
  float: right;
  width: 20%;
  min-height: 90px;
  background-color: #186f8d;
  text-align: right;
  padding-right: 4%;
}
.header {
  padding-top: 6px;
  padding-top: env(safe-area-inset-top);
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  background: #186f8d;
  color: #ffffff;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.header h1,
.header h2,
.header h3 {
  color: #ffffff;
}
.headerLeft {
  float: left;
  width: 10%;
  text-align: left;
  padding-top: 6px;
}
.headerCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  padding-top: 6px;
  padding-top: env(safe-area-inset-top);
}
.headerRight {
  float: right;
  width: 10%;
  text-align: right;
  padding-top: 6px;
}
.homeBackground {
  padding-top: 1px;
  padding-bottom: 12px;
  background-color: #f0f0f0;
}
.homeBackground p {
  color: #ffffff;
}
@media (prefers-color-scheme: dark) {
  .homeBackground {
    background: #000000;
  }
  .homeBackground p {
    color: #000000;
  }
}
.homeBox {
  margin: 0 auto;
  margin-top: 36px;
  margin-bottom: 36px;
  width: 92%;
  height: 300px;
  border-radius: 12px;
  background-color: #5e5e5e;
  box-shadow: 10px 10px 12px silver;
  position: relative;
}
@media (prefers-color-scheme: dark) {
  .homeBox {
    box-shadow: none;
  }
}
.homeBoxBottom {
  color: black;
  background-color: #ffffff;
  padding: 24px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.homeBoxBottom p {
  color: #000000;
}
@media (prefers-color-scheme: dark) {
  .homeBoxBottom {
    background: #323232;
  }
  .homeBoxBottom p {
    color: #ffffff;
  }
}
.progressbar {
  position: relative;
  height: 22px;
  background-color: #d4d4d4;
  border-radius: 50px;
  margin-top: 12px;
  margin-bottom: 36px;
  box-shadow: 10px 10px 12px silver;
}
@media (prefers-color-scheme: dark) {
  .progressbar {
    box-shadow: none;
    background-color: rgba(222, 222, 222, 0.6);
  }
}
.filler {
  background-color: #186f8d;
  width: 0%;
  height: 100%;
  border-radius: 50px;
  -webkit-transition: width 0.7s ease-in;
  -o-transition: width 0.7s ease-in;
  transition: width 0.7s ease-in;
}
.circle {
  width: 90px;
  height: 90px;
  border: 2px solid #5e5e5e;
  background: #ffffff;
  -moz-border-radius: 45px;
  -webkit-border-radius: 45px;
  border-radius: 45px;
  margin-bottom: 5px;
  box-shadow: 10px 10px 12px silver;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (prefers-color-scheme: dark) {
  .circle {
    box-shadow: none;
  }
}
.itemDiv {
  margin: 0 auto;
  width: 90px;
}
.itemDiv p {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.fixedBoxBottom {
  position: fixed;
  bottom: 10px;
  right: 10px;
  minWidth: 30%;
}
.footer {
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: center;
  -webkit-text-size-adjust: none;
  background: #ffffff;
}
.footer p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.4;
}
.footer h5 {
  color: #000000;
}
@media (prefers-color-scheme: dark) {
  .footer {
    background: #000000;
  }
  .footer p,
  .footer a,
  .footer h5 {
    color: #5e5e5e;
  }
}
